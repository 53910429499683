import React, { useEffect } from "react";
import HasUser from "../../funtions/hasUser";
import SiteBare from "./sitebare";
import TopBar from "./topbar";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { hasUser } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.user);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(hasUser({}));

        // Vérifiez si is_auth est vrai après que l'action ait été dispatchée
        if (data.is_auth === true) {
          // L'utilisateur est connecté
          // Vous pouvez effectuer des actions spécifiques ici
          navigate("/dashboard/ma-carte")
          console.log("Connecté");
        } else {
          // L'utilisateur n'est pas connecté
          // Vous pouvez effectuer des actions spécifiques ici
          console.log("Non connecté");
          navigate("/auth/login")
        }
      } catch (error) {
        console.error('Une erreur s\'est produite :', error);
        // Gérer l'erreur, par exemple, afficher un message d'erreur à l'utilisateur.
        navigate("/auth/login")
      }
    };

    fetchData();
  }, [dispatch, data.is_auth, navigate]);
  return (
    data.is_auth ?
    <div className="dashboard pt-5">
      <div className="container">
        <div className="row">
          <div className="col-12 mb-3 topbar">
            <TopBar />
          </div>
          <div className="col-md-3 ps-0">
            <div className="sidebar">
              <SiteBare />
            </div>
          </div>
          <div className="col-md-9 ">
            <div className="main"><Outlet /></div>{" "}
          </div>
        </div>
      </div>
    </div>
    :
    null
  );
}

export default Index;
