import React from "react";

function Card2({taux ="", partenaire="", description="", logo="#" }) {
  return (
    <div className="card2">
      <div className="img">
      {/* <img src={process.env.REACT_APP_BASE_API+"images/"+logo} alt="image de offre" /> */}
      <img src={logo} alt="image de offre" />
        <div className="reduction"><h5>-{taux}<sup><small>%</small></sup></h5></div>
      </div>
      <div className="content">
        <div className="entreprise">
          {partenaire}
        </div>
        <div className="description">
          <p>
            {description}
          </p>
        </div>
        {/* <div className="link">
          <a href="#">voir plus</a>
        </div> */}
      </div>
    </div>
  );
}

export default Card2;
// import React from "react";
// import { Card } from "./Card";
// import { BrowserRouter as Router, Route } from "react-router-dom";

// const List = ({ match, history }) => (
//   <ul className="card-list">
//     {cardData.map((card) => (
//       <Card
//         key={card.id}
//         isSelected={match.params.id === card.id}
//         history={history}
//         {...card}
//       />
//     ))}
//   </ul>
// );

// const Card2 = () => (
//   <Router>
//     <Route path={["/:id", "/"]} component={List} />
//   </Router>
// );

// const cardData = [
//   // Photo by ivan Torres on Unsplash
//   {
//     id: "c",
//     category: "Pizza",
//     title: "5 Food Apps Delivering the Best of Your City",
//     pointOfInterest: 80,
//     backgroundColor: "#814A0E",
//   },
//   // Photo by Dennis Brendel on Unsplash
//   {
//     id: "f",
//     category: "How to",
//     title: "Arrange Your Apple Devices for the Gram",
//     pointOfInterest: 120,
//     backgroundColor: "#959684",
//   },
//   // Photo by Alessandra Caretto on Unsplash
//   {
//     id: "a",
//     category: "Pedal Power",
//     title: "Map Apps for the Superior Mode of Transport",
//     pointOfInterest: 260,
//     backgroundColor: "#5DBCD2",
//   },
//   // Photo by Taneli Lahtinen on Unsplash
//   {
//     id: "g",
//     category: "Holidays",
//     title: "Our Pick of Apps to Help You Escape From Apps",
//     pointOfInterest: 200,
//     backgroundColor: "#8F986D",
//   },
//   // Photo by Simone Hutsch on Unsplash
//   {
//     id: "d",
//     category: "Photography",
//     title: "The Latest Ultra-Specific Photography Editing Apps",
//     pointOfInterest: 150,
//     backgroundColor: "#FA6779",
//   },
//   // Photo by Siora Photography on Unsplash
//   {
//     id: "h",
//     category: "They're all the same",
//     title: "100 Cupcake Apps for the Cupcake Connoisseur",
//     pointOfInterest: 60,
//     backgroundColor: "#282F49",
//   },
//   // Photo by Yerlin Matu on Unsplash
//   {
//     id: "e",
//     category: "Cats",
//     title: "Yes, They Are Sociopaths",
//     pointOfInterest: 200,
//     backgroundColor: "#AC7441",
//   },
//   // Photo by Ali Abdul Rahman on Unsplash
//   {
//     id: "b",
//     category: "Holidays",
//     title: "Seriously the Only Escape is the Stratosphere",
//     pointOfInterest: 260,
//     backgroundColor: "#CC555B",
//   },
// ];

// export default Card2;
