import { Alert } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function VerifCard() {
  const [info, setInfo] = useState({});
  const { cardId } = useParams();
  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_BASE_API +
          process.env.REACT_APP_CARTE_INFO +
          cardId
      )
      .then((res) => {
        console.log(res.data);
        if(res.data.status === false)
          window.location.href = "/auth/register"
        setInfo(res.data);
      });
  }, []);
  return (
    <div className="statut-card">
      <div className="verif-card ">
        <div>
          <div className="content ">
            <div className="text">
              <div className="tout">
                <div className="icon mb-2">
                    {
                        info.statut ?
                        <img src="/img/ok-icon.svg" alt="Abonnement en cours" />
                        :
                        <img src="/img/inactive.svg" alt="Abonnement en cours" />

                    }
                </div>
                <div className="title">
                  <h6>{info.statut ? "Abonnement en cours" : "Abonnement expiré"}</h6>
                </div>
                <ul className="ul1">
                  <li>
                    <span>N° de la carte:</span>
                    <span className="span2">{cardId}</span>
                  </li>
                  
                  <li>
                    <span>Prénom:</span>
                    <span className="span2">{info.prenom}</span>
                  </li>
                  <li>
                    <span>Nom:</span>
                    <span className="span2">{info.nom}</span>
                  </li>
                  {/* <li>
                    <span>CNI/Passport : </span>
                    <span className="span2">{info.cni}</span>
                  </li> */}
                </ul>
                {
                    info?.abonnements?.length > 0 ?
                    <ul className="ul2">
                  {info?.abonnements?.map((item) => (
                    <>
                      {/* <li>
                        <span>Abonnement :</span>
                        <span className="span2">{item.typeAbonnement}</span>
                      </li> */}
                      <li>
                        <span>Date de fin:</span>
                        <span className="span2">{item.datefin}</span>
                      </li>
                    </>
                  ))}
                </ul>
                :
          <Alert severity="warning">Cette carte n'a aucun abonnement</Alert>
                }
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifCard;
