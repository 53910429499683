import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect } from 'react'
import InfoAbonnement from './infoAbonnement';

function SelectAbonnement({setStepValid , infos, setInfos}) {
    const [value, setValue] = React.useState(infos.typeAbonnement);

    useEffect(() => {
        setStepValid(true)
    }, [])
    const handleChange = (event) => {
      setValue(event.target.value);

      setInfos(prevInfos => ({
        ...prevInfos,   
        dureeAbonnement: event.target.value
      }));
    };
    return ( 
        <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={value}
          defaultValue={infos.dureeAbonnement}
          onChange={handleChange}
        >
          <FormControlLabel value="mois" control={<Radio />} label="6 mois" />
          <FormControlLabel value="an" control={<Radio />} label="1 an" />
        </RadioGroup>
        <InfoAbonnement />
      </FormControl>
  
     );
}

export default SelectAbonnement;