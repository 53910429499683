import React, { useEffect, useState } from "react";
import Card2 from "../cards/card2";
import axios from "axios";
import Card4 from "../cards/card4";

function OffreSection({limit = true}) {
  const [offres, setOffres] = useState([])
  useEffect(() => {
      if(!limit){
        axios.get(
          process.env.REACT_APP_BASE_API +
            process.env.REACT_APP_OFFRE_LISTE, 
        ).then((res) => {
          
          setOffres(res.data)
        })
      }else{
      axios.get(
          process.env.REACT_APP_BASE_API +
            process.env.REACT_APP_OFFRE_TOT_LISTE, 
        ).then((res) => {
          
          setOffres(res.data)
        })
      }

  }, [])
  return (
    <div className="offresection ">
      <div className="container">
        {/* {limit && offres.length > 0 &&  */}
        <div className="sectionHeader">
          <div className="title">
            <div className="content">
              <h2>Nos top offres</h2>
              <p className="text-primary">
              Souriez on vous rend heureux !
              </p>
            </div>
          </div>
        </div>
        <div className="cardoffre">
          <div className="row text-center ">
            {/* {
              offres?.map((item, index) => (
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={item.tauxReduction} partenaire={item.partenaire} description={item.description} logo={item.logo} />{" "}
                </div>
              ))
            } */}
             <div className="col-lg-4  col-md-6">
                  <Card2 taux={20} partenaire={"Terrou Bi"} description={"nous vous proposons ..."} logo={"/img/offre_img.png"} />{" "}
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={25} partenaire={"Lagon"} description={"nous vous proposons ..."} logo={"/img/offre_img.png"} />{" "}
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={5} partenaire={"Onomo hotel"} description={"nous vous proposons ..."} logo={"/img/offre_img.png"} />{" "}
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={10} partenaire={"Résidences Mamounne"} description={"nous vous proposons ..."} logo={"/img/offre_img.png"} />{" "}
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={12} partenaire={"Yass hotel"} description={"nous vous proposons ..."} logo={"/img/offre_img.png"} />{" "}
                </div>
                <div className="col-lg-4  col-md-6">
                  <Card2 taux={11} partenaire={"Café de Rome"} description={"nous vous proposons ..."} logo={"/img/offre_img.png"} />{" "}
                </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OffreSection;
