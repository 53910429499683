import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import NumberCarte from './numberCArte';
import SelectAbonnement from './selectAbonnement';
import InfoPerso from './infoPerso';
import Paiement from './paiement';
import axios from 'axios';



export default function Index({isVirtual, horientation = "vertical"}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [direction, setDirection] = React.useState(horientation);
    const [stepValid, setStepValid] = React.useState(false)
    const [filteredSteps, setFilteredSteps] = React.useState([])
    const [infos, setInfos] = React.useState({
        numeroCarte : null,
        dureeAbonnement : "mois"
    })
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setStepValid(false)
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  let steps = []
  if (isVirtual){
     steps = [
        
       {
         label: "Durée de l'abonnement",
         description: <SelectAbonnement setStepValid={setStepValid} setInfos={setInfos} infos={infos} /> ,
       },
       {
         label: 'Ajouter mes informations personnelles',
         description: <InfoPerso setStepValid={setStepValid} setInfos={setInfos} infos={infos}/>,
       },
       {
         label: "Paiement",
         description: <Paiement setStepValid={setStepValid} setInfos={setInfos} infos={infos}/>,
       },
     ];
  }else{
   steps = [
     {
      label: 'Ajouter le numéro de votre carte',
      description: <NumberCarte setStepValid={setStepValid} setInfos={setInfos} infos={infos} />,
    },
    {
      label: 'Choisir un abonnement',
      description: <SelectAbonnement setStepValid={setStepValid} setInfos={setInfos} infos={infos} /> ,
    },
    {
      label: 'Ajouter mes informations personnelles',
      description: <InfoPerso setStepValid={setStepValid} setInfos={setInfos} infos={infos}/>,
    },
    {
      label: "Paiement",
      description: <Paiement setStepValid={setStepValid} setInfos={setInfos} infos={infos}/>,
    },
  ];}
  React.useEffect(() => {
     setFilteredSteps(isVirtual ? steps.slice(1) : steps  );

  }, [])

  React.useEffect(() => {
    function handleResize() {
      window.innerWidth <= 992 && setDirection("vertical")  ;
    }

    handleResize(); // Vérifie la taille de l'écran lors du chargement initial

    window.addEventListener('resize', handleResize); // Écoute les changements de taille d'écran
    return () => {
      window.removeEventListener('resize', handleResize); // Nettoie l'écouteur d'événement lors du démontage
    };
  }, []);

const sendData = () => {
  axios.post(process.env.REACT_APP_BASE_API+process.env.REACT_APP_PAIEMENT, infos)
  .then((res) => {
    window.location.href = res.data.paydunya
  }).catch((err) => alert("une erreur s'est produise"))
}

  return (
    <Box >
      <Stepper activeStep={activeStep} orientation={direction}>
        {steps.map((step, index) => {
            // if(index === 0 && isVirtual) 
            //     return  
          return <Step key={step.label}>
            <StepLabel
              optional={
                index === 3 ? (
                  <Typography variant="caption">Dernière étape</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              <Box>{step.description}</Box>
              <Box sx={{ mb: 2 }}>
                <div>
                  <button
                    class="btn btn-1 me-2"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1, }}
                    disabled={!stepValid}
                  >
                    {index === steps.length - 1 ? 'Passer au paiement' : 'Suivant'}
                  </button>
                  <Button
                  color="primary"
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    retour
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        })}
      </Stepper>
      {activeStep === steps.length && (
        sendData()
      )}
    </Box>
  );
}
