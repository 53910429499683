import { faAdversal } from '@fortawesome/free-brands-svg-icons';
import { faGift, faMoneyBillTrendUp, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { NavLink } from 'react-router-dom';
import MobileMenu from '../../components/sections/mobileMenu';
import { useSelector } from 'react-redux';

function SiteBare() {
  const user = useSelector((state) => state.user);
  const isNavLinkActive = (match, location) => {
        if (!match) {
          return false; // Si le lien ne correspond pas du tout, il n'est pas actif.
        }
      
        const { pathname } = location;
        return pathname === match.url; // Vérifie si le chemin correspond exactement.
      };
    return ( 
    <div className="sitebar d-none d-md-block">
        <ul>
                <NavLink to="/dashboard/ma-carte" isActive={false} activeClassName="active"> <li> <FontAwesomeIcon icon={faQrcode} className='me-2' /> Ma carte</li></NavLink>
                <NavLink to="/dashboard/mes-abonnements" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faMoneyBillTrendUp} className='me-2' /> Mes Abonnements</li></NavLink>
                {
                  user.roles.includes("ADMIN") && <>
                <NavLink to="/dashboard/partenaires" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faGift} className='me-2' />  Partenaires</li></NavLink>
                <NavLink to="/dashboard/offres" isActive={false} activeClassName="active"> <li><FontAwesomeIcon icon={faAdversal} className='me-2' />  Offres</li></NavLink>
                </>
                }
          </ul>
    </div> 
    );
}

export default SiteBare;