import React from 'react'
import Btn1 from '../buttons/button1';
import Btn2 from '../buttons/button2';

function PresentationSection() {
    return ( 
        <div className="presentationSection">
            <div className="container">
                <div className="row align-items-center">
                    <div className="d-none d-lg-block col-lg-6">
                        <div className="img">
                            <img src="/img/girl.png" alt="girl" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="content">
                            <div className="title">
                                <h2>
                                La carte Teraalma  vous offre des <span> avantages et privilèges uniques</span></h2>
                            </div>
                            <div className="text">
                                <p className="text1g">
                                chez des partenaires qui s’engagent à vous donner le sourire. Profitez de moment privilégies dans les meilleurs restaurants pour vos sorties, hôtels pour vos séjours, magasins pour vos shopping,
                                excursions touristiques pour vos vacances à des Prix Teraalma. Vous bénéficiez aussi des services prioritaires totalement exclusifs.
                                </p>
                            </div>
                            <div className="buttons">
                                <Btn1 href="/carte-virtuelle" text="Obtenir ma carte" displayIcon={true} />
                                <Btn2 href="/contact" text="Contactez-nous" color='text-white' />

                            </div>
                        </div>
                    </div>
                    <div className="d-block d-lg-none col-lg-6">
                        <div className="img">
                            <img src="/img/girl.png" alt="girl" /> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default PresentationSection;